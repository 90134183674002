import { Collapse as MuiCollapse, Fade, Stack as MuiStack } from '@mui/material'
import { styled } from '@mui/system'
import Typography from '../../atoms/typography'
import Button from '../button'

const shouldForwardProp = (prop) => !['additionalContent', 'flipped', 'look'].includes(prop)

const Collapse = styled(MuiCollapse)`
	.MuiCollapse-wrapper {
		position: relative;
		.MuiCollapse-wrapperInner {
			display: flex;
			justify-content: center;
			border-top: 1px solid #333333;
			align-items: center;
			${({ theme }) => theme.breakpoints.up('md')} {
				border: 0;
			}
		}
	}
`

const Stack = styled(MuiStack)`
	order: 2;
`

const ButtonStyled = styled(Button, { shouldForwardProp })`
	color: ${({ look, theme }) => (look === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.main)};
	padding: 11px;
	z-index: 1;
	${({ flipped }) =>
		flipped
			? `
			transform: rotate(180deg);
			transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		`
			: ''}
	.MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	}
`

const TypographyStyled = styled(Typography, { shouldForwardProp })`
	order: 1;
	background-color: transparent;
	color: ${({ look, theme }) => (look === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.main)};
	font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
	padding: ${({ theme }) => theme.spacing(0, 1)};
	${({ additionalContent, theme }) =>
		!additionalContent
			? `
			${theme.breakpoints.up('md')} {
				font-family: ${theme.typography.fontFamily};
				font-weight: ${theme.typography.fontWeightRegular};
			}
		`
			: 'cursor: pointer;'}
`

const CollapseAdditionalContent = styled(Fade)`
	background-color: ${({ theme }) => theme.palette.primary.main};
	color: ${({ theme }) => theme.palette.white.main};
	box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 6px 6px -1px rgba(10, 22, 70, 0.1);
	left: 0;
	position: absolute;
	top: 42px;
	width: 100%;
	z-index: 1;
	height: calc(80vh - var(--header-height)) !important;
	overflow-y: auto;
	${({ theme }) => theme.scrollbar};
	::-webkit-scrollbar {
		width: 4px;
	}
	${({ theme }) => theme.breakpoints.up('1023px')} {
		height: auto !important;
	}
	${({ theme }) => theme.breakpoints.up('sm')} {
		width: 100%;
	}
	> .MuiBox-root {
		padding: ${({ theme }) => theme.spacing(3)} !important;
		margin: 0px !important;
		${({ theme }) => theme.breakpoints.up('sm')} {
			padding: ${({ theme }) => theme.spacing(0)};
			margin: 0px -3px;
		}
		.MuiGrid-item {
			padding: 0px 0;
			${({ theme }) => theme.breakpoints.up('sm')} {
				padding: 0 3px;
			}
		}
	}
	a {
		color: ${({ theme }) => theme.palette.white.main};
	}
`

const Container = styled('div')`
	padding: ${({ theme }) => theme.spacing(0, 0, 3)};
`

export { ButtonStyled, Collapse, CollapseAdditionalContent, Container, Stack, TypographyStyled }
