import { PAGE_PATH_HOME } from '@bluheadless/ui-logic/src/constants'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useStore } from '@bluheadless/ui-logic/src/providers/store'
import { cx } from '@emotion/css'
import MuiGrid from '@mui/material/Grid'
import Link from '../../molecules/link'
import Image from '../../particles/image'
import { Header, LogoWrapper } from './header-error.styled'

const HeaderError = ({ logoProps: _logoProps, className }) => {
	const {
		siteName: siteName,
		logo: { light: { image: logoLightSrc, width: logoLightWidth, height: logoLightHeight } = {} } = {},
	} = useConfig()
	const store = useStore()

	// if store.code is undefined, forceInternal needs to be false,
	// otherwise link will redirect to home/en causing an error and geo-ip will not work.
	const forceInternal = !store?.code ? false : undefined

	const logoProps = {
		..._logoProps,
		src: logoLightSrc,
		originalWidth: logoLightWidth,
		originalHeight: logoLightHeight,
	}

	const { originalWidth, originalHeight, ...props } = logoProps

	return (
		<Header className={cx('HeaderError-root', className)}>
			<MuiGrid
				alignItems="center"
				container
				spacing={{
					xs: 1,
					sm: 4.5,
				}}
			>
				<MuiGrid item xs={12}>
					<LogoWrapper>
						<Link href={PAGE_PATH_HOME} forceInternal={forceInternal}>
							<Image
								originalHeight={originalHeight}
								originalWidth={originalWidth}
								{...props}
								alt={siteName}
								title={siteName}
							/>
						</Link>
					</LogoWrapper>
				</MuiGrid>
			</MuiGrid>
		</Header>
	)
}

export default HeaderError
