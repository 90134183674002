import { styled } from '@mui/system'
import { Stack as MuiStack } from '@mui/material'
import Icon from '../../atoms/icon'
import Typography from '../../atoms/typography'

const Stack = styled(MuiStack)`
	cursor: pointer;
`

const SvgIcon = styled(Icon)`
	font-size: ${({ theme }) => theme.typography.pxToRem(19)};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(22)};
	}
`

const Label = styled(Typography)`
	display: none;
`

export { Label, Stack, SvgIcon }
